<template>
  <div class="billing-wrapper">
    <div class="billing-header">
      <div class="title">Billing</div>
    </div>
    <div
      class="account-payment"
      :class="{'inctive-payment': isAccountDisabled}"
    >
      <div class="payment-title">Account Payment</div>
      <div class="payment-container">
        <div class="title-container">
          <div class="title">Subscription status:
            <div :class="subscriptionStatusClass">&nbsp;{{currentSubscriptionStatus}}</div>
          </div>
          <div class="title">
            Expiration date: {{
              currentUser.subscription.current_period_end
                ? getFormattedBillingDate(currentUser.subscription.current_period_end)
                : 'You don\'t have a subscription'
            }}
          </div>
        </div>
        <ActionButton
          :width="'151px'"
          :height="'44px'"
          :text="subscriptionBtnText"
          :class="{'inactive-btn': isSubscriptionBtnDisabled}"
          v-if="!isSubscriptionBtnLoading"
          @click="handleSubscriptionButtonClick"
        />
        <div
          class="subscription-action-loading-indicator"
          v-if="isSubscriptionBtnLoading"
        >
          <div class="spinner-container"><div class="spinner"></div></div>
        </div>
      </div>
    </div>
    <div class="account-cards">
      <div class="account-cards-info">
        <div class="accounts-card-text">
          <div class="subsection-title">Credit or debit cards</div>
          <div class="account-cards-description">
            The card will be debited on the date the bill is paid. All major credit / debit cards are accepted.
          </div>
        </div>
        <ActionButton
          :width="'151px'"
          :height="'44px'"
          :text="'Add card'"
          :class="{'inactive-btn': isAddCardBtnDisabled}"
          @click="showCardModal"
        />
        <NewCardModal
          :isCardModalShown="isCardModalShown"
          @hideCardModal="hideCardModal"
        />
      </div>
      <div class="account-cards-list">
        <Card
          v-for="card in cardsList"
          :key="card.id"
          :cardId="card.id"
          :last4="card.last4"
          :userDefaultCard="currentUser.subscription.default_payment_method"
          :cardExpirationMonth="card.exp_month"
          :cardExpirationYear="card.exp_year"
          :isActionsAvailable="currentUser.subscription.default_payment_method !== card.id"
          @actionMenuItemClick="handleActionMenuClick($event, card.id)"
        />
      </div>
    </div>
    <div class="error-message">{{errorMessage}}</div>
    <div class="billings-history-container">
      <div class="billings-history-title">Billing history</div>
      <div class="billings-history-table">
        <div class="billings-history-table-header billings-history-table-row">
          <div class="billings-history-table-field billing-date">
            Date
          </div>
          <div class="billings-history-table-field billing-description">
            Description
          </div>
          <div class="billings-history-table-field billing-amount">
            Amount
          </div>
          <div class="billings-history-table-field billing-status">
            Status
          </div>
          <div class="billings-history-table-field billing-action">
            Action
          </div>
        </div>
        <div class="billing-list">
          <div
            class="billings-history-table-row"
            v-for="record in billingHistory"
            :key="record.id"
          >
            <div class="billings-history-table-field billing-date">
              {{getFormattedBillingDate(record.created)}}
            </div>
            <div class="billings-history-table-field billing-description">
              Payment ({{record.card}})
            </div>
            <div class="billings-history-table-field billing-amount">
              {{'$' + record.amount / 100}}
            </div>
            <div
              class="billings-history-table-field billing-status"
              :class="{'successful-payment': record.status === 'succeeded'}"
            >
              {{record.status}}
            </div>
            <div class="billings-history-table-field billing-action">
              <div class="dowload-receipt-btn" @click="handleReceiptDownloading(record.receipt_url)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-overlay" v-if="isLoading">
      <Spinner
        :width="'35px'"
        :height="'35px'"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import NewCardModal from './NewCardModal.vue';
import ActionButton from '../../common/ActionButton.vue'
import Spinner from '../../common/Spinner.vue'
import Card from '../../common/cards/Card.vue'

import * as subscriptionsApi from '../../../api/subscriptionsApi'
import { UserSubscriptionStatus } from '../../../utils/constants'

export default {
  components: {
    NewCardModal,
    ActionButton,
    Spinner,
    Card,
  },
  data() {
    return {
      billingHistory: [],
      errorMessage: '',
      UserSubscriptionStatus: UserSubscriptionStatus,
      isLoading: true,
      isSubscriptionBtnLoading: false,
      isCardModalShown: false,
    }
  },
  async created() {
    await this.fetchInitialData()
  },
  computed: {
    ...mapState([
      'currentUser',
      'cardsList',
    ]),
    isAccountDisabled() {
      const statuses = [
        UserSubscriptionStatus.NotStarted,
        UserSubscriptionStatus.Incomplete,
        UserSubscriptionStatus.Stopped,
      ]

      return statuses.includes(this.currentUser.subscription.status)
    },
    subscriptionBtnText() {
      const inactiveStatuses = [
        UserSubscriptionStatus.NotStarted,
        UserSubscriptionStatus.Canceled,
        UserSubscriptionStatus.Stopped,
      ]
      const activeStatuses = [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus["Granted Until"],
      ]

      if (inactiveStatuses.includes(this.currentUser.subscription.status)) {
        return 'Pay'
      }

      if (activeStatuses.includes(this.currentUser.subscription.status)) {
        return 'Unsubscribe'
      }

      if (this.currentUser.subscription.status === UserSubscriptionStatus.Incomplete) {
        return 'Processing'
      }
    },
    currentSubscriptionStatus() {
      return Object.keys(UserSubscriptionStatus)
        .find(key => UserSubscriptionStatus[key] === this.currentUser.subscription.status)
    },
    subscriptionStatusClass() {
      const activeSubscriptionStatuses = [
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus["Granted Until"],
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Canceled,
      ]

      if (activeSubscriptionStatuses.includes(this.currentUser.subscription.status)) {
        return 'active-subscription'
      }

      return 'inactive-subscription'
    },
    isSubscriptionBtnDisabled() {
      return (this.currentUser.subscription.status === UserSubscriptionStatus.Canceled)
        || (this.currentUser.subscription.status === UserSubscriptionStatus.Granted)
    },
    isAddCardBtnDisabled() {
      return (this.currentUser.subscription.status === UserSubscriptionStatus.Granted)
        || (!this.currentUser.subscription.default_payment_method)
    },
  },
  methods: {
    ...mapActions([
      'updateUserLocally',
      'getCardsList',
      'setCardAsDefault',
      'deleteCard',
    ]),
    async fetchInitialData() {
      try {
        await this.getCardsList()
        this.billingHistory = await subscriptionsApi.getBillingHistory()
      } catch (error) {
        this.errorMessage = 'Something went wrong, please, try again...'
      } finally {
        this.isLoading = false
      }
    },
    getFormattedBillingDate(timestamp) {
      const date = new Date(timestamp * 1000)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()

      return `${month}/${day}/${year}`
    },
    handleReceiptDownloading(url) {
      window.open(url)
    },
    async cancelSubscription() {
      await subscriptionsApi.cancelSubscription()
      await this.updateUserLocally({ subscription: { status: UserSubscriptionStatus.Canceled }})
    },
    async activateSubscription() {
      this.$router.push({ name: 'Subscriptions' })
    },
    async handleSubscriptionButtonClick() {
      const userSubscriptionStatus = this.currentUser.subscription.status
      const inactiveSubscriptionStatuses = [
        UserSubscriptionStatus.NotStarted,
        UserSubscriptionStatus.Stopped,
      ]

      try {
        this.isSubscriptionBtnLoading = true

        if (userSubscriptionStatus === UserSubscriptionStatus.Active) {
          await this.cancelSubscription()
          return
        }

        if (inactiveSubscriptionStatuses.includes(userSubscriptionStatus)) {
          await this.activateSubscription()
          return
        }
      } catch (error) {
        this.errorMessage = 'Something went wrong, please, try again...'
      } finally {
        this.isSubscriptionBtnLoading = false
      }
    },
    showCardModal() {
      if (this.isAddCardBtnDisabled) return

      this.isCardModalShown = true
    },
    hideCardModal() {
      this.isCardModalShown = false
    },
    async handleActionMenuClick(evt, cardId) {
      this.isLoading = true

      try {
        if (evt === 'Make default') {
          if (this.isAddCardBtnDisabled) {
            this.errorMessage = 'You can\'t switch default card while using granted subscription.'
            return
          }

          await this.setCardAsDefault(cardId)
        } else if (evt === 'Delete card') {
          await this.deleteCard(cardId)
        }
      } catch (error) {
        this.errorMessage = 'Something went wrong, please, try again...'
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.billing-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;

  .billing-header {
    width: calc(100% - 60px);
    height: 60px;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $fontColorBlack;
    font-size: $fontSizeModalTitle;
  }

  .inactive-payment {
    width: calc(100% - 60px);
    height: 145px;
    background-color: #FADEDE !important;
    border: 1px dashed red;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }

  .account-payment {
    width: calc(100% - 60px);
    min-height: 145px;
    background-color: #F4F5FC;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 0 24px;
    box-sizing: border-box;

    .payment-title {
      margin-top: 30px;
      color: $fontColorBlack;
    }

    .payment-container {
      margin-top: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title-container {
        width: 429px;

        .title {
          margin-top: 2px;
          color: #535971;
          display: flex;

          .active-subscription {
            color: #40BA40;
          }

          .inactive-subscription {
            color: $redWarningColor
          }
        }
      }

      .action-button {
        margin-top: 0px !important;
      }

      .subscription-action-loading-indicator {
        width: 151px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $appActionColor;
        color: $fontColorWhite;
        border-radius: 4px;

        .spinner-container {
          width: 100%;
          height: calc(100% - 104px);
          display: flex;
          justify-content: center;
          align-items: center;

          .spinner {
            border: 7px solid #FFFFFF;
            border-radius: 50%;
            border-top: 7px solid $appActionColor;
            width: 12px;
            height: 12px;
            animation: spin 2s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        }
      }
    }
  }

  .account-cards {
    width: calc(100% - 60px);
    margin-top: 10px;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #F4F5FC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;

    .account-cards-info {
      width: 100%;
      height: 100px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      .accounts-card-text {
        width: 429px;

        .account-cards-description {
          margin-top: 24px;
          color: #535971;
        }
      }

      .action-button {
        align-self: center;
      }
    }

    .account-cards-list {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .card-wrapper {
    margin-top: 12px;
    width: calc(100% - 60px);
    height: 249px;
    background-color: #F4F5FC;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    .card-title {
      margin-left: 24px;
      margin-top: 10px;
    }

    .title-container {
      .title {
        margin-left: 24px;
        margin-top: 2px;
        width: 95%;
        height: 44px;
        color: #535971;
        display: flex;
      }
    }
  }

  .error-message {
    width: calc(100% - 60px);
    height: 20px;
    min-height: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }

  .billings-history-container {
    width: calc(100% - 60px);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .billings-history-title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: $fontSizeModalTitle;
      margin-bottom: 20px;
    }

    .billings-history-table {
      width: 100%;
      border: 1px solid #F4F5FC;
      border-radius: 4px;

      .billings-history-table-header {
        background-color: #F4F5FC;
        color: #888888;
      }

      .billings-history-table-row {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #F4F5FC;
        color: #535971;

        .billings-history-table-field {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .billing-date {
          width: 150px;
        }

        .billing-description {
          width: 230px;
        }

        .billing-amount {
          width: 100px;
          justify-content: center;
        }

        .billing-status {
          width: 105px;
          justify-content: center;
        }

        .successful-payment {
          color: #40BA40;
        }

        .billing-action {
          width: 48px;
          justify-content: center;

          .dowload-receipt-btn {
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../../assets/icons/download-icon.svg');
            cursor: pointer;

            &:hover {
              background-image: url('../../../assets/icons/download-active-icon.svg');
            }
          }
        }
      }

      .billing-list {
        width: 100%;
        padding-bottom: 10px;
      }
    }
  }

  .inactive-btn {
    background-color: #9C9EA6 !important;
    cursor: not-allowed;
  }

  .loading-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
  }
}
</style>
