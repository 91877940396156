<template>
  <div class="user-payment-card">
    <div class="card-info">
      <div class="card-number-container">
        <div class="card-number">xxxx-xxxx-xxxx-{{last4}}</div>
        <div class="default-card-badge" v-if="cardId === userDefaultCard">DEFAULT</div>
      </div>
      <div class="expiration-date">
        Expires at {{cardExpMonthFormatted + '/' + cardExpirationYear}}
      </div>
    </div>
    <div class="action-menu-container">
      <MoreActionsBtn
        :width="'44px'"
        :height="'44px'"
        :isActive="isActionMenuShown"
        v-if="isActionsAvailable"
        @click="openActionMenu"
      />
      <ActionMenu
        :width="'280px'"
        :height="'98px'"
        :top="'52px'"
        :isActionMenuShown="isActionMenuShown"
        @closeActionMenu="closeActionMenu"
      >
        <ActionMenuItem
          v-for="(action, index) in menuActions"
          :key="index"
          :text="action"
          @actionMenuItemClick="handleActionMenuItemClick(action)"
        />
      </ActionMenu>
    </div>
  </div>
</template>

<script>
import MoreActionsBtn from "../../common/MoreActionsBtn.vue"
import ActionMenu from "../../common/actionMenu/ActionMenu"
import ActionMenuItem from "../../common/actionMenu/ActionMenuItem"

export default {
  props:[
    'cardId',
    'last4',
    'cardExpirationMonth',
    'cardExpirationYear',
    'userDefaultCard',
  ],
  data() {
    return {
      isActionMenuShown: false,
      menuActions: ['Make default', 'Delete card'],
    }
  },
  components:{
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
  },
  computed: {
    isActionsAvailable() {
      return this.cardId !== this.userDefaultCard
    },
    cardExpMonthFormatted() {
      if (this.cardExpirationMonth && this.cardExpirationMonth < 10) return '0' + this.cardExpirationMonth
      else return this.cardExpirationMonth
    },
  },
  methods: {
    openActionMenu() {
      this.isActionMenuShown = true
    },
    closeActionMenu() {
      this.isActionMenuShown = false
    },
    handleActionMenuItemClick(action) {
      this.isActionMenuShown = false
      this.$emit('actionMenuItemClick', action)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.user-payment-card {
  width: 100%;
  min-width: 300px;
  height: 90px;
  padding: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  background: linear-gradient(270deg, #4A90E2 0%, #0072CE 100%);
  display: flex;
  justify-content: space-between;

  .card-info {
    display: flex;
    flex-direction: column;

    .card-number-container {
      display: flex;
      color: $whiteColor;

      .card-number {
        font-size: 16px;
      }

      .default-card-badge {
        font-size: 10px;
        width: 63px;
        height: 22px;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1167BD;
        border-radius: 4px;
      }
    }

    .expiration-date {
      margin-top: 10px;
      font-size: 14px;
      color: #B7D3F3;
    }
  }

  .action-menu-container {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    .more-actions-btn {
      border: none;
      background-image: url('../../../assets/icons/more-white-icon.svg');
    }
  }
}

.user-payment-card:nth-last-child(1) {
  margin-bottom: 0;
}
</style>
